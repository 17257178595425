import { graphql, useStaticQuery } from "gatsby";
import React, { FC } from "react";
import BlockWrapper from "../features/BlockWrapper";
import TextGrid, { TextGridWrapperAbsolute } from "../features/TextGrid";
import { useResponsive } from "../hooks/useResponsive";
import Claim from "../partials/Claim";
import Menu from "../partials/Menu";
import Background from "../ui/Cover";
import PageWrapper from "../ui/PageWrapper";
import { getCoverUrl } from "../utils/helpers";
import { DataT, SwcT } from "../utils/types";

const Swc: FC = () => {
  const { strapiSwc } = useStaticQuery(query);
  const swcData: DataT<SwcT> = strapiSwc;

  const { tabletVersion, mobileVersion } = useResponsive();

  const { cover, text } = swcData.data.attributes;

  return (
    <PageWrapper>
      <>
        <Menu isHomePage={false} />
        <Claim />
        <BlockWrapper
          cover={
            <>
              <Background
                url={getCoverUrl(
                  tabletVersion || mobileVersion,
                  cover.desktop,
                  cover.mobile
                )}
              />
              {text && (
                <TextGridWrapperAbsolute>
                  <TextGrid items={[text]} linksToNewTab={true} />
                </TextGridWrapperAbsolute>
              )}
            </>
          }
        />
      </>
    </PageWrapper>
  );
};

export default Swc;

const query = graphql`
  query {
    strapiSwc {
      data {
        attributes {
          text {
            highlight_color
            id
            paragraph_size
            text
            width
            text_color
            x_offset
            y_offset
          }
          cover {
            desktop {
              data {
                attributes {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;
